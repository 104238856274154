<template>
  <v-app>
    <v-main style="height: 100vh; width: 100%; background-color: #ff5722;">
        <div style="font-size: 2rem; color: #fff; text-align: center; margin-top:5%;">
          PAGE NOT FOUND!!!
        </div>
    </v-main>
  </v-app>
</template>

<script>
export default {};
</script>

<style>
</style>